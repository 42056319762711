import { createSlice } from '@reduxjs/toolkit';

export const postlistSlice = createSlice({
  name: 'property',
  initialState: {
    property: null    
  },
  reducers: {
    getPostlist() {},
    setPostlist(state, action) {      
      const listingData = action.payload;
      state.property = listingData
      return state;
    },
    addPostlistvalue() {}
  }
})

export const { getPostlist, setPostlist, addPostlistvalue, purgePostlistState } = postlistSlice.actions;

export default postlistSlice.reducer;
import axios from "axios";

export default function requestCreatePropertyval(endpoint){

    const {pagename, pageid, type, id, data } = endpoint;

    // const propValue = (values) => {
    //     for (let val in values) {   
    //       if(values[val] === true || values[val] === false) {
    //         if(values[val] === true) {
    //           values[val] = 'true'
    //         }else{
    //           values[val] = 'false'
    //         }
    //       }
    //     }
    //     return values;
    // };  

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    //backoffice/:lang/product/:productid/version/:id

    return axios.put(process.env.REACT_APP_API_URL+'/en/'+pagename+'/'+pageid+'/'+type+'/'+id, data, config);
}
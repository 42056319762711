import { createSlice } from '@reduxjs/toolkit';

export const singleSlice = createSlice({
  name: 'single',
  initialState: {
    isError: false,
    isErrorTxt: false,
    isSuccess: false,
    pagename: null,
    title: null,
    id: null,
    url: null,
    params: null,
    post: []
  },
  reducers: {
    createSingle() {},
    setParams(state, action) {
      const { pagename, id } = action.payload;
      state.pagename = pagename;
      state.id = id;
    },
    getSingle() {},    
    setSingle(state, action) {      
      const { post } = action.payload;
      state.post = post.items;
      state.id = post.id;
      state.url = post.url;
      state.title = post.title;
      state.pagename = post.pagename;
      state.isSuccess = false;
      state.isError = false;
      state.isErrorTxt = false;
      // return state;
    },
    updateSingle(state, action) {
      const singleData = action.payload;
      state.isSuccess = true;
      state.isError = false;
      state.isErrorTxt = false;
      state.post = [...singleData];
      return state;
    },
    sendParams(state, action) {
      const { params } = action.payload;
      state.param = params;
    },
    setIs(state) {
      state.isSuccess = false;
      state.isError = false;
      state.isErrorTxt = false;
    },    
    setError(state, action) {
      const txt = action.payload;
      state.isSuccess = false;
      state.isError = true;
      state.isErrorTxt = txt;
      return state;
    },
    purgeSingleState(state){
      state.isSuccess = false;
      state.isError = false;
      state.isErrorTxt = false;
      state.id = null;
      state.pagename = null;
      state.post = [];      
      return state;
    }
  }
})

export const { getSingle, setSingle, createSingle, updateSingle, setError, purgeSingleState, setParams, sendParams, setIs } = singleSlice.actions;

export default singleSlice.reducer;

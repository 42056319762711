import { call, put } from "redux-saga/effects";
import { addPostlistvalue } from "../../slices/postlistvalue";
import requestCreatePropertyval from "../request/createpostlistvalue";
import errors from "src/redux/saga/errors";

export function* handlePostlistCreatevalue(action) {
    try {
        const { payload } = action;        
        const response = yield call(requestCreatePropertyval, payload);
        const { data } = response;        
        yield put(addPostlistvalue(data));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}
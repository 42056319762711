import { call, put } from "redux-saga/effects";
import { setPostlistvalue } from "../../slices/postlistvalue";
import requestgetPostlistvalue from "../request/postlistvalue";
import errors from "src/redux/saga/errors";

export function* handlePostlistvalue(action) {
    try {
        const { payload } = action;
        const response = yield call(requestgetPostlistvalue, payload);
        const { data } = response;
        yield put(setPostlistvalue(data));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}
import { call, put } from "redux-saga/effects";
import { setPostlist } from "../../slices/postlist";
import requestgetPostList from "../request/postlist";
import errors from "src/redux/saga/errors"

export function* handlePostlist(action) {
    try {
        const { payload } = action;
        const response = yield call(requestgetPostList, payload);
        const { data } = response;        
        yield put(setPostlist(data));
    } catch (error){   
        errors(error);     
        console.log(error);        
    }
}
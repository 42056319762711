import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { store } from 'src/redux/store';
import { purgeSingleState } from "../redux/slices/single";

// ----------------------------------------------------------------------

const purgeSingle = () => store.dispatch(purgeSingleState());

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('src/views/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.general.ecommerce,
      component: lazy(() => import('src/views/DashboardEcommerceView'))
    },
    // {
    //   exact: true,
    //   path: PATH_APP.general.analytics,
    //   component: lazy(() => import('src/views/DashboardAnalyticsView'))
    // },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />
    },
    // SETTING
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.root,
      component: () => <Redirect to={PATH_APP.app.languages} />
    },    
    {
      exact: true,
      path: PATH_APP.app.language,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.user,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.template,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.property,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.tracking,
      component: lazy(() => import('src/views/Tracking'))
    },
    {
      exact: true,
      path: PATH_APP.app.fillform ,
      component: lazy(() => import('src/views/Listing'))
    },        
    // WEARHOUSE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.warehouse.import,
      component: lazy(() => import('src/views/Import'))
    },
    {
      exact: true,
      path: PATH_APP.warehouse.search,
      component: lazy(() => import('src/views/Search'))
    },    
    // APP
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.page,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.category,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.brand,
      component: lazy(() => import('src/views/Listing'))
    },    
    {
      exact: true,
      path: PATH_APP.app.product,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.blog,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.studio,
      component: lazy(() => import('src/views/Listing'))
    }, 
    {
      exact: true,
      path: PATH_APP.app.service,
      component: lazy(() => import('src/views/Listing'))
    },            
    {
      exact: true,
      path: PATH_APP.app.discount,
      component: lazy(() => import('src/views/Listing'))
    }, 
    {
      exact: true,
      path: PATH_APP.app.order,
      component: lazy(() => import('src/views/Listing'))
    }, 
    {
      exact: true,
      path: PATH_APP.app.customer,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.casehistory,
      component: lazy(() => import('src/views/Listing'))
    },
    {
      exact: true,
      path: PATH_APP.app.storelocator,
      component: lazy(() => import('src/views/Listing'))
    }, 
    {
      exact: true,
      path: PATH_APP.app.importstorelocator,
      component: lazy(() => import('src/views/Importexport'))
    },                        
    // SINGLE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.single,
      component: lazy(() => import('src/views/Single')),
      onLeave: purgeSingle
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;

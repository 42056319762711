import axios from "axios";

export default function requestgetPostList(endpoint){

    const {typeid, pagename, type} = endpoint;

    const data = {
        "searchterm": "",
        "pagesize": 10000
    };

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    return axios.post(process.env.REACT_APP_API_URL+'/it/'+pagename+'/'+typeid+'/'+type, data, config);
}
import axios from "axios";

export default function requestgetPostlistvalue(endpoint){

    const {id, typeid, pagename, value } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    return axios.get(process.env.REACT_APP_API_URL+'/it/'+pagename+'/'+typeid+'/'+value+'/'+id, config);

}
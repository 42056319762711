// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app'
};

export const PATH_HOME = {
  root: '/',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-setting/',
  home: path(ROOTS.home, '/home/home')
};

export const PATH_AUTH = {
  root: ROOTS.auth,
  login: path(ROOTS.auth, '/login')
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce'),
    analytics: path(ROOTS.app, '/dashboard/analytics')
  },
  setting: {

  },
  warehouse: {
    root: path(ROOTS.app, '/warehouse'),
    import: path(ROOTS.app, '/warehouse/import'),
    search: path(ROOTS.app, '/warehouse/search')
  },  
  app: {
    root: path(ROOTS.app, '/setting'),
    language: path(ROOTS.app, '/setting/language'),
    user: path(ROOTS.app, '/setting/user'),
    template: path(ROOTS.app, '/setting/template'),
    property: path(ROOTS.app, '/setting/property'),    
    page: path(ROOTS.app, '/page'),
    category: path(ROOTS.app, '/category'),
    brand: path(ROOTS.app, '/brand'),
    product: path(ROOTS.app, '/product'),
    tracking: path(ROOTS.app, '/tracking'),
    discount: path(ROOTS.app, '/discount'),
    order: path(ROOTS.app, '/order'),
    customer: path(ROOTS.app, '/customer'),
    blog: path(ROOTS.app, '/blog'),
    studio: path(ROOTS.app, '/studio'),
    service: path(ROOTS.app, '/service'),
    casehistory: path(ROOTS.app, '/casehistory'),
    fillform: path(ROOTS.app, '/fillform'),
    storelocator: path(ROOTS.app, '/storelocator'),
    importstorelocator: path(ROOTS.app, '/importexport/storelocator'),
  },
  single: {
    post: path(ROOTS.app, '/:entity/single/:id'),
  }
};

import { createSlice } from '@reduxjs/toolkit';

export const slider = createSlice({
  name: 'propertyval',
  initialState: {
    openslide: false,
    slide: null,
    entity: null,
    entityid: null,
    name: null,
    id: null
  },
  reducers: {
    createSlide() {},
    getSlide() {},
    setSlide(state, action) {      
      const slide = action.payload;
      state.slide = slide;
      // return state;
    },      
    openSlide(state, action) {
      const { openslide, entity, entityid, name, id } = action.payload;
      state.openslide = openslide;
      state.entity = entity;
      state.entityid = entityid;
      state.name = name;
      state.id = id;
    },
    purgeSlide(state, action) {
      state.openslide = false;
      state.entity = null;
      state.slide = null;
      state.entityid = null;
      state.name = null;
      state.id = null;      
    }
  }
})

export const { openSlide, getSlide, setSlide, createSlide, purgeSlide } = slider.actions;

export default slider.reducer;
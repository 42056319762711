import { call, put } from "redux-saga/effects";
import { setSlide } from "../../slices/slider";
import requestCreateSlider from "../request/createslider";
import errors from "src/redux/saga/errors";
import { getSingle } from 'src/redux/slices/single';

export function* handleCreateSlider(action) {
    try {
        const { payload } = action;
        const response = yield call(requestCreateSlider, payload);
        const { data } = response;
        yield put(setSlide(data));
        yield put(getSingle({title: payload.entity, id: payload.entityid}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}